<template>
  <v-card
    class="sticky-note text-left"
    :class="noteColor"
    :hover="hover"
    :ripple="{ center: false }"
    :style="{ color: styles.app.color }"
    @click="onGoToDetail"
  >
    <v-row no-gutters>
      <v-col>
        <!-- プレビュー表示時は依頼者の部署を表示 -->
        <div v-if="preview" class="caption">{{ me.department.name }}</div>

        <!-- プレビューでない場合はクエストの部署を表示 -->
        <div v-else class="caption">{{ quest.department.name }}</div>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col>
        <div class="title text-h6 font-weight-bold">{{ quest.title||'---' }}</div>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col>
        <v-chip
          v-if="questCategory"
          color="primary"
          small
          v-text="questCategory"
        />
      </v-col>
    </v-row>

    <v-row no-gutters class="mb-n1">
      <v-col>
        <div class="d-inline-block text-subtitle-2 font-weight-bold" style="width: 70px">
          報酬:
        </div>
        <div class="d-inline-block red--text text--lighten-1">
          <span class="text-h5 font-weight-bold">{{ intcomma(quest.reward) }}</span>
          <span class="caption font-weight-bold">&nbsp;ポイント</span>
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters class="mb-n1">
      <v-col>
        <div class="d-inline-block text-subtitle-2 font-weight-bold" style="width: 70px">契約人数:</div>
        <div class="d-inline-block">
          <span class="font-weight-bold">0</span>
          <span class="caption">人 (募集人数: {{ quest.recruitmentNumbers||1 }}人)</span>
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col>
        <div class="d-inline-block align-center text-subtitle-2 font-weight-bold" style="height: 47px; width: 70px; vertical-align: middle;">
          応募期限:
        </div>
        <div class="d-inline-block">
          <span class="subtitle-1">あと</span>
          <span class="font-weight-bold">{{ calcDaysUntil(quest.deadline) }}</span>
          <span class="subtitle-1">日</span>
          <div class="mt-n2 caption">({{ dateFormat(quest.deadline)||'yyyy年mm月dd日' }}まで)</div>
        </div>
      </v-col>
    </v-row>

    <div
      class="client-name subtitle-1 text-end caption"
      style="position: absolute; bottom: 0.4em;"
    >
      <v-avatar
        class="me-1"
        :color="getProfileImgByToken(preview ? me.token : quest.client.token) ? 'white' : 'blue-grey lighten-1'"
        size="20"
      >
        <img v-if="getProfileImgByToken(preview ? me.token : quest.client.token)" :src="getProfileImgByToken(preview ? me.token : quest.client.token)" />
        <v-icon v-else color="white" size="20">mdi-account</v-icon>
      </v-avatar>

      <!-- プレビュー表示時はユーザーの名前表示 -->
      <span v-if="preview">{{ me.nickname }}</span>

      <!-- プレビュー表示でない場合は依頼者の名前表示 -->
      <span
        v-else
        class="link"
        @click.prevent.stop="onShowPublicProfile"
      >
        {{ quest.client.nickname }}
      </span>
    </div>
  </v-card>
</template>

<script>
import AppMethodsMixin from '@/mixins/AppMethodsMixin';
import AppStyleMixin from '@/mixins/AppStyleMixin';
import { quest as blankQuest } from '@/store/models/quest';

export default {
  mixins: [AppMethodsMixin, AppStyleMixin],
  props: {
    hover: {
      type: Boolean,
      default: false
    },
    noteColor: {
      type: String,
      default: 'note-blue'
    },
    preview: {
      type: Boolean,
      default: false
    },
    quest: {
      type: Object,
      default: () => blankQuest
    }
  },
  computed: {
    me() {
      return this.$store.getters['users/getMe'];
    },
    questCategory() {
      // プレビュー(クエスト登録)でない場合はクエストのカテゴリーをそのまま返す
      if (!this.preview) {
        return this.quest.category.name;
      }

      // プレビューの場合はStoreのカテゴリーから名称を取得して返す
      const questCategories = this.$store.getters['questCategories/getCategories'];
      for (let i = 0; i <= questCategories.length; i++) {
        if (questCategories[i] && questCategories[i].id === this.quest.category) {
          return questCategories[i].name;
        }
      }
      return null;
    }
  },
  methods: {
    onGoToDetail(e) {
      this.$emit('go-to-detail', e);
    },
    onShowPublicProfile(e) {
      this.$emit('on-show-public-profile', e);
    }
  }
};
</script>

<style lang="scss" scoped>
.sticky-note {
  width: 19.5rem;
  min-width: 19.5rem;
  height: 18.5rem;
  padding: 0.8em;
  transform: rotate(0deg);
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0.25rem 0.25rem;
  font-size: 1.125rem;
  line-height: 1.8;

  // タイトルは3行まで表示、溢れる部分は「...」に変える
  .title {
    height: 95px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  // 発注者名が長すぎる場合、溢れる部分を「...」に変える
  .client-name {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  // 付箋の背景色
  &.note-blue {
    background-image: linear-gradient(
      rgba(115, 115, 115, 0.09) 2rem,
      rgba(255, 255, 255, 0) 2.5rem
    ),
    linear-gradient(rgb(179, 238, 255), rgb(179, 238, 255));
  }

  // 付箋の背景色
  &.note-yellow {
    background-image: linear-gradient(
      rgba(115, 115, 115, 0.09) 2rem,
      rgba(255, 255, 255, 0) 2.5rem
    ),
    linear-gradient(rgb(247, 255, 179), rgb(247, 255, 179));
  }
}
</style>
