/**
 * クエスト新規登録用のモデル
 */
export const quest = {
  userCreated: {},
  department: {},
  uuid: '',
  title: '',
  content: '',
  recruitmentNumbers: 1,
  deadline: '',
  delivery: '',
  reward: '',
  status: '',
  completedAt: '',
  category: {
    id: null,
    name: null
  }
};
