<!-- クエスト依頼時のタイムラインアイテム -->
<template>
  <v-timeline-item :class="className">
    <v-card class="outset" :style="styles.gradient" color="primary">
      <v-card-title>
        <span class="text-h6 font-white"><slot name="title" /></span>
      </v-card-title>
      <v-card-text class="text--primary" :style="domStyle">
        <slot name="content" />
      </v-card-text>
    </v-card>
  </v-timeline-item>
</template>

<script>
import AppStyleMixin from '@/mixins/AppStyleMixin';

export default {
  name: 'OrderQuestTimelineItem',
  mixins: [AppStyleMixin],
  props: {
    className: {
      type: String,
      default: ''
    },
    domStyle: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
