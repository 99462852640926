<!-- クエストを依頼する -->
<template>
  <v-form ref="form" v-model="valid">
    <v-container class="mb-6 mt-10">
      <div class="text-start mb-5">クエストを依頼する</div>

      <v-row>
        <v-col cols="12" md="8">
          <v-timeline align-top dense>
            <!-- カテゴリー選択 -->
            <order-quest-timeline-item :dom-style="styles.app">
              <template v-slot:title>
                STEP ①&emsp;カテゴリーを1つ選択しましょう
              </template>
              <template v-slot:content>
                <v-card flat :style="styles.app">
                  <v-list :style="styles.app">
                    <v-list-item-group v-model="quest.category">
                      <template v-for="(questCategory, i) in questCategories">
                        <v-divider
                          v-if="i !== 0"
                          :key="'divider-' + i"
                        />
                        <v-list-item
                          :key="questCategory.id"
                          :style="styles.app"
                          :value="questCategory.id"
                          active-class="font-weight-bold"
                          color="primary"
                        >
                          <v-list-item-content>
                            <v-list-item-title v-text="questCategory.name" />
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </template>
            </order-quest-timeline-item>

            <!-- クエストの内容 -->
            <order-quest-timeline-item
              :class-name="'mt-15'"
              :dom-style="styles.app"
            >
              <template v-slot:title>
                STEP ②&emsp;クエストの内容を入力しましょう
              </template>
              <template v-slot:content>
                <v-container class="px-2">
                  <v-row>
                    <v-col cols="12" md="12">
                      <base-text-field
                        :counter="50"
                        :label="'タイトル'"
                        :rules="[maxLength(50) ,required]"
                        :value="quest.title"
                        @input="onInput({ title: $event })"
                      />
                    </v-col>
                    <v-col cols="12" md="12">
                      <base-text-area
                        :counter="2000"
                        :label="'内容'"
                        :name="'input-7-4'"
                        :outlined="true"
                        :rules="[maxLength(2000), required]"
                        :value="quest.content"
                        @input="onInput({ content: $event })"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </order-quest-timeline-item>

            <!-- 応募期限/完了期限 -->
            <order-quest-timeline-item
              :class-name="'mt-15'"
              :dom-style="styles.app"
            >
              <template v-slot:title>
                STEP ③&emsp;クエストの期限を設定しましょう
              </template>
              <template v-slot:content>
                <v-container class="px-2">
                  <v-row>
                    <v-col cols="12" md="12">
                      <base-date-picker
                        :allowed-dates="allowedDates"
                        :close-on-content-click="false"
                        :label="'応募期限'"
                        :min-width="'auto'"
                        :no-title="true"
                        :offset-y="true"
                        :readonly="true"
                        :ref-name="'deadline'"
                        :rules="[required]"
                        :scrollable="true"
                        :transition="'scale-transition'"
                        :value="quest.deadline"
                        @input="onInput({ deadline: $event })"
                      />
                    </v-col>
                    <v-col cols="12" md="12">
                      <base-date-picker
                        :allowed-dates="allowedDates"
                        :close-on-content-click="false"
                        :label="'完了期限'"
                        :min-width="'auto'"
                        :no-title="true"
                        :offset-y="true"
                        :readonly="true"
                        :ref-name="'delivery'"
                        :rules="[required, customRules.delivery]"
                        :scrollable="true"
                        :transition="'scale-transition'"
                        :value="quest.delivery"
                        @input="onInput({ delivery: $event })"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </order-quest-timeline-item>

            <!-- 報酬 -->
            <order-quest-timeline-item
              :class-name="'mt-15 pb-15'"
              :dom-style="styles.app"
            >
              <template v-slot:title>
                STEP ④&emsp;報酬額を設定しましょう
              </template>
              <template v-slot:content>
                <v-container class="px-2">
                  <v-row>
                    <v-col cols="12" md="12">
                      <base-text-field
                        :label="'報酬額'"
                        :rules="[maxNumber(99999999), required]"
                        :suffix="'ポイント'"
                        :value="quest.reward"
                        @input="onInput({ reward: $event })"
                        @keypress="onNumberInput($event)"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </order-quest-timeline-item>
          </v-timeline>
        </v-col>

        <!--  -プレビュー -->
        <v-col
          cols="12"
          md="4"
          class="mb-10"
          style="pointer-events: none;"
          :style="$vuetify.breakpoint.smAndDown ? 'margin: 0 auto;' : 'position: fixed; right: 0px; margin: 0 auto;'"
        >
          <v-icon>mdi-eye</v-icon><span class="font-weight-bold">「クエストを探す」公開後プレビュー</span>
          <v-card class="inset rounded-md mt-3 py-10" :style="styles.app">
            <div class="d-flex justify-center">
              <quest-sticky-note :quest="quest" :preview="true" />
            </div>
          </v-card>
        </v-col>
      </v-row>

      <base-button
        :class-name="'outset py-6 rounded-xl font-weight-bold white--text'"
        :color="'orange darken-1'"
        :disabled="!valid || !quest.category"
        @click="toConfirm"
      >
        <template v-slot:text>依頼内容を確認する</template>
      </base-button>
    </v-container>
  </v-form>
</template>

<script>
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);

import BaseButton from '@/components/atoms/BaseButton';
import BaseDatePicker from '@/components/atoms/BaseDatePicker';
import AppMethodsMixin from '@/mixins/AppMethodsMixin';
import AppStyleMixin from '@/mixins/AppStyleMixin';
import BaseTextArea from '@/components/atoms/BaseTextArea';
import BaseTextField from '@/components/atoms/BaseTextField';
import FormRulesMixin from '@/mixins/FormRulesMixin';
import OrderQuestTimelineItem from '@/components/organisms/OrderQuestTimelineItem';
import QuestStickyNote from '@/components/organisms/QuestStickyNote';
import { quest as blankQuest } from '@/store/models/quest';
import { isEmpty } from 'lodash-es';
import store from '@/store';

export default {
  name: 'Create',
  components: {
    BaseButton,
    BaseDatePicker,
    BaseTextArea,
    BaseTextField,
    OrderQuestTimelineItem,
    QuestStickyNote
  },
  mixins: [AppMethodsMixin, AppStyleMixin, FormRulesMixin],
  data() {
    return {
      quest: Object.assign({}, blankQuest),
      currentDate: new Date(),
      valid: false,
      showDeadlinePicker: false,
      showDeliveryPicker: false,
      questCategories: []
    };
  },
  computed: {
    customRules() {
      return {
        delivery: () => {
          return dayjs(this.quest.delivery).isAfter(dayjs(this.quest.deadline)) || '応募期限より後の日付を入力してください';
        }
      };
    }
  },
  methods: {
    onInput (quest) {
      this.quest = { ...this.quest, ...quest };
    },
    /** 登録内容確認画面へ */
    toConfirm() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$store.dispatch('quests/toConfirm', this.quest);

      // クエスト内容修正の場合
      if (this.$route.params.questId) {
        this.$router.push({ path: 'confirm', append: true });

      // 新規登録の場合
      } else {
        this.$router.push({ name: 'CreateConfirm' });
      }
    },
    /** Datepickerフォームで選択可能な日付を「今日以降の日付」に限定するための関数 */
    allowedDates(date) {
      return dayjs(date).isSameOrAfter(this.currentDate, 'day');
    },
    /** クエストカテゴリー取得 */
    async getQuestCategories() {
      return await this.$store.dispatch('questCategories/list');
    }
  },
  async mounted() {
    this.showLoadingOverlay(async () => {
      await this.getQuestCategories(); // クエストカテゴリー取得
      this.questCategories = this.$store.getters['questCategories/getCategories'];

      // Storeにクエスト入力情報が保存されている場合は、その情報で上書き
      // （登録内容確認画面から戻ってきた場合を考慮）
      if (!isEmpty(this.$store.getters['quests/getCurrentQuest'])) {
        this.quest = this.$store.getters['quests/getCurrentQuest'];
      } else if (JSON.parse(localStorage.getItem('currentQuest'))) {
        this.quest = JSON.parse(localStorage.getItem('currentQuest'));
      }

      // 登録内容編集時はクエストカテゴリーがオブジェクト型なので、カテゴリーIDだけを取り出し上書きする
      if (typeof this.quest.category === 'object') {
        this.quest.category = this.quest.category.id;
      }
    });
  },
  beforeRouteEnter(to, from, next) {
    next(() => {
      // 登録内容確認画面からの遷移でない場合は入力内容を破棄する
      if (from.name !== 'CreateConfirm') {
        store.commit('quests/setCurrentQuest', null);
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.v-sheet {
  box-shadow: none;
}
.v-sheet.v-stepper:not(.v-sheet--outlined) {
  box-shadow: none;
}
</style>
